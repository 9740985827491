/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {
	getLastMileLabelRequest,
	getParcelRequest,
	setLabelNull,
} from 'redux/reducers/parcels/reducer';
import { Loader } from 'ui/Loader';
import { getLabel } from 'redux/reducers/parcels/selectors';
import { createSignalRContext } from 'react-signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { getAccessToken } from 'redux/reducers/auth/selectors';
import printJS from 'print-js';
import useInterval from 'use-interval';
import queryString from 'query-string';

const SignalRContext = createSignalRContext();

export const LastMail: FC = () => {
	const params = useParams<{ parcelId: string }>();
	const file = useSelector(getLabel);
	const navigate = useNavigate();
	const location = useLocation();

	const dispatch = useDispatch();

	const inputRef = useRef<HTMLInputElement>(null);

	useInterval(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, 1000);

	const soundClickError = () => {
		const audio = new Audio();
		audio.src = '/audio/error.mp3';
		audio.autoplay = true;
	};
	const soundClickOk = () => {
		const audio = new Audio();
		audio.src = '/audio/ok.mp3';
		audio.autoplay = true;
	};
	const soundClickWarning = () => {
		const audio = new Audio();
		audio.src = '/audio/error1.mp3';
		audio.autoplay = true;
	};

	const soundClickCompleted = () => {
		const audio = new Audio();
		audio.src = '/audio/completed.mp3';
		audio.autoplay = true;
	};

	const soundClickCollected = () => {
		const audio = new Audio();
		audio.src = '/audio/collected.mp3';
		audio.autoplay = true;
	};

	function handleDownloadBlob(stream: Blob) {
		const pdfBlob = new Blob([stream], { type: 'application/pdf' });
		const pdfUrl: string | null = URL.createObjectURL(pdfBlob);
		console.log('handleDownloadBlob', pdfBlob);
		console.log('handleDownloadBlob pdfUrl', pdfUrl);

		printJS({
			printable: pdfUrl,
			type: 'pdf',
			showModal: true,
			onPrintDialogClose: () => {
				pdfUrl && URL.revokeObjectURL(pdfUrl);
				dispatch(setLabelNull());
				// navigate(`/${location.search}`);
			},
		});
	}

	useEffect(() => {
		if (file) {
			handleDownloadBlob(file);
		}
	}, [file]);

	const token = useSelector(getAccessToken);

	const [messages, setMessages] = useState<string[]>([]);
	const [connection, setConnection] = useState<HubConnection | null>(null);
	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(
				`https://parcelcollection.easproject.com/api/parcelItemsConnection?token=${
					token as string
				}`,
			)
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection as unknown as HubConnection);
	}, []);

	const sendMessage = async () => {
		if (connection) {
			await connection.invoke('BlockParcel', +(params?.parcelId ?? 0));
		}
	};

	useEffect(() => {
		if (connection) {
			connection.start().then(() => {
				console.log('Подключение установлено!');
			});
		}
	}, [connection]);

	useEffect(() => {
		if (connection) {
			connection.on('checkParcel', (message) => {
				// console.log('useSignalREffect', message);
				setMessages([...messages, message]);
				sendMessage();
			});
		}
	}, [connection]);

	const ping = () => {
		SignalRContext.invoke('BlockParcel', +(params?.parcelId ?? 0));
	};
	// SignalRContext.useSignalREffect(
	// 	'checkParcel',
	// 	(message) => {
	// 		console.log('useSignalREffect', message);
	// 		ping();
	// 		setMessage(JSON.stringify(message));
	// 	},
	// 	[],
	// );

	useEffect(() => {
		ping();
	}, []);

	useEffect(() => {
		dispatch(getParcelRequest({ parcelId: params?.parcelId ?? '' }));
	}, [dispatch, params]);

	const handleChange = (checked: number, index: number) => {
		// // ping();
		// const newCheckedArray = [...checkedArray];
		// newCheckedArray[index] = checked;
		// setCheckedArray(newCheckedArray);
	};

	const [sku, setSku] = useState<string>('');

	const [skuIndex, setSkuIndex] = useState<number>(-1);
	const [skuInput, setSkuInput] = useState<string>('');

	const handleChangeKeyDown = (index: number) => {
		// const newCheckedArray = [...checkedArray];
		// if (parcel?.[index].quantity === 1) {
		// 	newCheckedArray[index] = 1;
		// } else if (
		// 	(parcel?.[index].quantity ?? 0) > 1 &&
		// 	newCheckedArray[index] < (parcel?.[index].quantity ?? 0)
		// )
		// 	newCheckedArray[index] += 1;
		// setCheckedArray(newCheckedArray);
	};

	const onKeyDown = () => {
		// const indexTmp =
		// 	parcel?.findIndex((item) => item.sku.toUpperCase() === sku.trim().toUpperCase()) ?? -1;
		// setSkuIndex(indexTmp);
		// setSkuInput(sku);
		// setSku('');
		// if (indexTmp === -1) {
		// 	soundClickError();
		// 	return;
		// }
		// if (parcel?.[indexTmp].quantity === checkedArray[indexTmp]) {
		// 	setSkuIndex(-2);
		// 	soundClickWarning();
		// 	return;
		// }
		// if (parcel?.[indexTmp].quantity === checkedArray[indexTmp] + 1) {
		// 	soundClickCompleted();
		// 	handleChangeKeyDown(indexTmp);
		// 	return;
		// }
		// const checkedArrayTmp = [...checkedArray];
		// checkedArrayTmp[indexTmp] += 1;
		// if (!checkedArrayTmp.some((item, index) => item < (parcel?.[index]?.quantity ?? 0))) {
		// 	soundClickCollected();
		// }

		dispatch(getLastMileLabelRequest({ meestId: sku.trim() }));
		setSkuInput(sku);
		setSku('');

		soundClickOk();
		// handleChangeKeyDown(indexTmp);
		// console.log('checkedArray', checkedArray);
	};

	return (
		<Container maxWidth="xl" sx={{ mb: '50px' }}>
			<Paper>
				<div
					style={{
						display: 'flex',
						background: '#016193',
						color: 'white',
						margin: 0,
						padding: '10px 0 0 10px',
						borderRadius: '5px 5px 0 0',
						justifyContent: 'space-between',
						minHeight: '200px',
					}}
				>
					<h1 style={{ color: '#64ff64' }}>{skuInput}</h1>
					<input
						type="text"
						autoFocus
						value={sku}
						onChange={(e) => setSku(e.target.value)}
						style={{ opacity: '0', position: 'fixed', top: 0 }}
						onKeyDown={(e) => {
							if (e.code === 'Enter' && sku) onKeyDown();
						}}
						ref={inputRef}
					/>
				</div>
			</Paper>
		</Container>
	);
};
