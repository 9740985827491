import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import { TDeleteDialogProps } from './types';

export const DeleteDialog: FC<TDeleteDialogProps> = ({
	open,
	setClose,
	handleDelete,
	header,
	text,
}) => {
	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle>{header}</DialogTitle>
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button onClick={setClose}>Cancel</Button>
				<Button onClick={handleDelete} autoFocus>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};
