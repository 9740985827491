/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import {
	generatedLabelRequest,
	getLabelRequest,
	getParcelRequest,
	setLabelNull,
	setParcelCollectedRequest,
	setParcelProblemRequest,
} from 'redux/reducers/parcels/reducer';
import { Loader } from 'ui/Loader';
import { DeleteDialog } from 'ui/DeleteDialog';
import { Button, Checkbox } from '@mui/material';
import { getLabel, getParcel, getParcelIsLoad } from 'redux/reducers/parcels/selectors';
import { createSignalRContext } from 'react-signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { getAccessToken } from 'redux/reducers/auth/selectors';
import printJS from 'print-js';
import useInterval from 'use-interval';
import { OrderStratus } from 'components/Home/OrderStratus';
import queryString from 'query-string';
import { TOrdersStatus } from 'redux/reducers/parcels/types';
import {
	ORDERS_STATUS_COLLECTED,
	ORDERS_STATUS_NOT_STARTED,
	ORDERS_STATUS_PROBLEMATIC,
} from 'redux/reducers/parcels/constants';
import { MultiCheckbox } from './MultiCheckbox';

const SignalRContext = createSignalRContext();

export const Parcel: FC = () => {
	const params = useParams<{ parcelId: string }>();
	const file = useSelector(getLabel);
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();

	const { selfStatus } = queryString.parse(search);

	const dispatch = useDispatch();
	const parcel = useSelector(getParcel);
	const parcelIsLoad = useSelector(getParcelIsLoad);

	const [typeDialog, setTypeDialog] = useState('cancel');
	const inputRef = useRef<HTMLInputElement>(null);
	const [checkedArray, setCheckedArray] = useState<number[]>(parcel?.map(() => 0) ?? [0]);

	useInterval(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, 1000);

	const soundClickError = () => {
		const audio = new Audio();
		audio.src = '/audio/error.mp3';
		audio.autoplay = true;
	};
	const soundClickOk = () => {
		const audio = new Audio();
		audio.src = '/audio/ok.mp3';
		audio.autoplay = true;
	};
	const soundClickWarning = () => {
		const audio = new Audio();
		audio.src = '/audio/error1.mp3';
		audio.autoplay = true;
	};

	const soundClickCompleted = () => {
		const audio = new Audio();
		audio.src = '/audio/completed.mp3';
		audio.autoplay = true;
	};

	const soundClickCollected = () => {
		const audio = new Audio();
		audio.src = '/audio/collected.mp3';
		audio.autoplay = true;
	};

	const allCollected = () => {
		if (parcel) {
			return checkedArray.every((item, index) => item === parcel[index].quantity);
		}
		return false;
	};

	useEffect(() => {
		if (allCollected()) {
			soundClickCollected();
		}
	}, [checkedArray]);

	// const handleDownloadBlob1 = (stream: Blob) => {
	// 	const blob = new Blob([stream], { type: 'application/pdf' });
	// 	const url = window.URL.createObjectURL(blob);
	// 	const win = window.open(url, '_blank');
	// 	if (win) {
	// 		console.log('onafterprint');
	// 		win.onafterprint = () => {
	// 			console.log('afterprint');
	// 			win?.close();
	// 			window.URL.revokeObjectURL(url);
	// 			navigate(`/${location.search}`);
	// 		};
	// 	}
	// 	setTimeout(() => {
	// 		win?.focus();
	// 		win?.print();
	// 	}, 2000);
	// };

	const [print, setPrint] = useState(false);

	function handleDownloadBlob(stream: Blob) {
		const pdfBlob = new Blob([stream], { type: 'application/pdf' });
		const pdfUrl: string | null = URL.createObjectURL(pdfBlob);

		printJS({
			printable: pdfUrl,
			type: 'pdf',
			showModal: true,
			onPrintDialogClose: () => {
				pdfUrl && URL.revokeObjectURL(pdfUrl);
				dispatch(setLabelNull());
				navigate(`/${location.search}`);
			},
		});
	}

	useEffect(() => {
		if (file) {
			handleDownloadBlob(file);
		}
	}, [file]);

	const token = useSelector(getAccessToken);

	const [messages, setMessages] = useState<string[]>([]);
	const [connection, setConnection] = useState<HubConnection | null>(null);
	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(
				`https://parcelcollection.easproject.com/api/parcelItemsConnection?token=${
					token as string
				}`,
			)
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection as unknown as HubConnection);
	}, []);

	const sendMessage = async () => {
		if (connection) {
			await connection.invoke('BlockParcel', +(params?.parcelId ?? 0));
		}
	};

	useEffect(() => {
		if (connection) {
			connection.start().then(() => {
				console.log('Подключение установлено!');
			});
		}
	}, [connection]);

	useEffect(() => {
		if (connection) {
			connection.on('checkParcel', (message) => {
				// console.log('useSignalREffect', message);
				setMessages([...messages, message]);
				sendMessage();
			});
		}
	}, [connection]);

	const ping = () => {
		SignalRContext.invoke('BlockParcel', +(params?.parcelId ?? 0));
	};
	// SignalRContext.useSignalREffect(
	// 	'checkParcel',
	// 	(message) => {
	// 		console.log('useSignalREffect', message);
	// 		ping();
	// 		setMessage(JSON.stringify(message));
	// 	},
	// 	[],
	// );

	useEffect(() => {
		ping();
	}, []);

	useEffect(() => {
		dispatch(getParcelRequest({ parcelId: params?.parcelId ?? '' }));
	}, [dispatch, params]);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	useEffect(() => {
		setCheckedArray(parcel?.map(() => 0) ?? [0]);
	}, [parcel]);

	const handleChange = (checked: number, index: number) => {
		// ping();
		const newCheckedArray = [...checkedArray];
		newCheckedArray[index] = checked;
		setCheckedArray(newCheckedArray);
	};

	const handleCancel = () => {
		setTypeDialog('cancel');
		setOpenDeleteDialog(true);
	};
	const handleCancelDialog = () => {
		navigate(`/${location.search}`);
	};
	const handleProblematic = () => {
		setTypeDialog('problematic');
		setOpenDeleteDialog(true);
	};
	const handleProblematicDialog = () => {
		dispatch(setParcelProblemRequest({ parcelId: params?.parcelId ?? '' }));
		navigate(`/${location.search}`);
	};
	const handleAddedToParcel = () => {
		setTypeDialog('added');
		setOpenDeleteDialog(true);
	};
	const handleAddedToParcelDialog = () => {
		dispatch(setParcelCollectedRequest({ parcelId: params?.parcelId ?? '' }));
		setPrint(true);
		dispatch(getLabelRequest({ parcelId: params?.parcelId ?? '' }));

		// navigate(`/${location.search}`);
	};

	const handlePrintLabel = () => {
		setTypeDialog('print');
		setOpenDeleteDialog(true);
	};

	const handlePrintLabelDialog = () => {
		setPrint(true);
		dispatch(generatedLabelRequest({ parcelId: params?.parcelId ?? '' }));
	};

	const getDialogHandler = () => {
		SignalRContext.invoke('UnBlockParcel', +(params?.parcelId ?? 0));
		handleCloseDeleteDialog();
		if (typeDialog === 'cancel') handleCancelDialog();
		if (typeDialog === 'problematic') handleProblematicDialog();
		if (typeDialog === 'added') handleAddedToParcelDialog();
		if (typeDialog === 'print') handlePrintLabelDialog();
	};

	const getDialogHeader = () => {
		if (typeDialog === 'cancel')
			return 'Are you sure you want to cancel the processing of the current parcel?';
		if (typeDialog === 'problematic')
			return 'Are you sure you want to mark the current parcel as problematic?';
		if (typeDialog === 'added')
			return 'Are you sure you want to mark the current parcel as collected?';
		if (typeDialog === 'print')
			return 'Are you sure you want to print the label for the current parcel?';
		return '';
	};

	const [sku, setSku] = useState<string>('');

	const [skuIndex, setSkuIndex] = useState<number>(-1);
	const [skuInput, setSkuInput] = useState<string>('');

	// const handleCheckedArray = (index: number, check: boolean) => {
	// 	if (parcel?.[index].quantity === 1) setCheckedArray();

	// 	setCheckedArray(checkedArray.map((item, i) => (i === index ? !item : item)));
	// };
	const handleChangeKeyDown = (index: number) => {
		const newCheckedArray = [...checkedArray];
		// if (parcel?.[index].quantity === newCheckedArray[index]) {
		// 	setSkuIndex(-2);
		// 	return;
		// }

		if (parcel?.[index].quantity === 1) {
			newCheckedArray[index] = 1;
		} else if (
			(parcel?.[index].quantity ?? 0) > 1 &&
			newCheckedArray[index] < (parcel?.[index].quantity ?? 0)
		)
			newCheckedArray[index] += 1;
		setCheckedArray(newCheckedArray);
	};

	const onKeyDown = () => {
		const indexTmp =
			parcel?.findIndex((item) => item.sku.toUpperCase() === sku.trim().toUpperCase()) ?? -1;
		setSkuIndex(indexTmp);
		setSkuInput(sku);
		setSku('');
		if (indexTmp === -1) {
			soundClickError();
			return;
		}
		if (parcel?.[indexTmp].quantity === checkedArray[indexTmp]) {
			setSkuIndex(-2);
			soundClickWarning();
			return;
		}
		if (parcel?.[indexTmp].quantity === checkedArray[indexTmp] + 1) {
			soundClickCompleted();
			handleChangeKeyDown(indexTmp);
			return;
		}
		// const checkedArrayTmp = [...checkedArray];
		// checkedArrayTmp[indexTmp] += 1;
		// if (!checkedArrayTmp.some((item, index) => item < (parcel?.[index]?.quantity ?? 0))) {
		// 	soundClickCollected();
		// }

		soundClickOk();
		handleChangeKeyDown(indexTmp);
		// console.log('checkedArray', checkedArray);
	};

	return (
		<Container maxWidth="xl" sx={{ mb: '50px' }}>
			{!parcel?.length && !parcelIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No parcel found...</p>
				</div>
			)}
			{!!parcelIsLoad && <Loader />}
			{parcel?.length && !parcelIsLoad && (
				<Paper>
					<div
						style={{
							display: 'flex',
							background: '#016193',
							color: 'white',
							margin: 0,
							padding: '10px 0 0 10px',
							borderRadius: '5px 5px 0 0',
							justifyContent: 'space-between',
						}}
					>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<h1
								style={{
									marginRight: '50px',
									// 	background: '#016193',
									// 	color: 'white',
									// 	margin: 0,
									// 	padding: '10px 0 0 10px',
									// 	borderRadius: '5px 5px 0 0',
								}}
							>
								{params?.parcelId}
							</h1>
							<OrderStratus
								status={selfStatus as TOrdersStatus}
								invert={selfStatus === ORDERS_STATUS_NOT_STARTED}
							/>
						</div>
						<h1
							style={{
								color: `${
									skuInput && skuIndex === -1
										? '#ff4c6a'
										: skuInput && skuIndex === -2
										? '#f4b867'
										: '#64ff64'
								}`,
								marginRight: '20px',
							}}
						>
							{skuInput} {skuInput && skuIndex === -1 && ' - not found!'}{' '}
							{skuInput && skuIndex === -2 && ' - already completed!'}
						</h1>
						<input
							type="text"
							autoFocus
							value={sku}
							onChange={(e) => setSku(e.target.value)}
							style={{ opacity: '0', position: 'fixed', top: 0 }}
							onKeyDown={(e) => {
								if (e.code === 'Enter' && sku) onKeyDown();
							}}
							ref={inputRef}
						/>
					</div>
					<TableContainer sx={{ maxHeight: 800 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="left" colSpan={2}>
										SKU
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={3}>
										Product description
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										Quantity
									</StyledTableCell>
									{selfStatus === ORDERS_STATUS_NOT_STARTED && (
										<StyledTableCell align="center" colSpan={1}>
											Added to parcel
										</StyledTableCell>
									)}{' '}
								</TableRow>
							</TableHead>
							<TableBody>
								{parcel.map((item, index) => (
									<StyledTableRow hover key={item.id}>
										<StyledTableCell align="left" colSpan={2}>
											{item.sku}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={3}>
											{item.title}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											{item.quantity}
										</StyledTableCell>
										{selfStatus === ORDERS_STATUS_NOT_STARTED && (
											<StyledTableCell align="center" colSpan={1}>
												{item.quantity === 1 ? (
													<Checkbox
														checked={!!checkedArray?.[index] ?? false}
														onChange={(e) => handleChange(e.target.checked ? 1 : 0, index)}
													/>
												) : (
													<MultiCheckbox
														num={item.quantity}
														checkAll={handleChange}
														index={index}
														checkNum={checkedArray[index]}
													/>
												)}{' '}
											</StyledTableCell>
										)}{' '}
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			)}

			<div style={{ marginTop: '20px' }}>
				<Button
					variant="contained"
					onClick={handleCancel}
					style={{ marginRight: '20px' }}
					color="warning"
				>
					Cancel
				</Button>
				{selfStatus !== ORDERS_STATUS_PROBLEMATIC && (
					<Button
						variant="contained"
						onClick={handleProblematic}
						color="error"
						style={{ marginRight: '20px' }}
					>
						Mark as problematic
					</Button>
				)}
				{selfStatus === ORDERS_STATUS_NOT_STARTED && (
					<Button
						variant="contained"
						onClick={handleAddedToParcel}
						disabled={!allCollected()}
						color="success"
					>
						Parcel Collected
					</Button>
				)}{' '}
				{selfStatus === ORDERS_STATUS_COLLECTED && (
					<Button variant="contained" onClick={handlePrintLabel} color="primary">
						Print Label
					</Button>
				)}{' '}
			</div>
			<DeleteDialog
				open={openDeleteDialog}
				setClose={handleCloseDeleteDialog}
				handleDelete={getDialogHandler}
				header={getDialogHeader()}
				text={
					typeDialog === 'added' || typeDialog === 'print' ? '' : 'selected items will not be saved'
				}
			/>
		</Container>
	);
};

// 15952534
// MCDM-KNW-STD2
// MCDM-STICKER-LASER
// SNF-BOOK-STD
