/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
// import { Checkbox } from '@mui/material';

import { Button, Checkbox, Menu, MenuItem } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export type MultiCheckboxProps = {
	options: string[];
	onChange: (value: string[]) => void;
	value: string[];
};
export type TMultiCheckboxProps = {
	num: number;
	checkAll: (check: number, index: number) => void;
	index: number;
	checkNum: number;
};

// export const Checkbox = (props: any) => {
// 	return (
// 		<div>
// 			<input type="checkbox" {...props} />
// 			{/* {props.children} */}
// 		</div>
// 	);
// };
export const MultiCheckbox: FC<TMultiCheckboxProps> = ({ num, checkAll, index, checkNum }) => {
	// const { options, onChange, value } = props;

	const [options, setOptions] = useState<string[]>(['1', '2', '3']);
	const [value, setValue] = useState<string[]>([]);
	useEffect(() => {
		const newOptions = [];
		for (let i = 0; i < num; i++) {
			newOptions.push(String(i));
		}
		setOptions(newOptions);
	}, [num]);

	useEffect(() => {
		const newValue = [];
		for (let i = 0; i < checkNum; i++) {
			newValue.push(String(i));
		}
		setValue(newValue);
	}, [checkNum]);

	const handleChange = (option: string) => {
		if (value.includes(option)) {
			setValue(value.filter((v) => v !== option));
		} else {
			setValue([...value, option]);
			console.log('option', [...value, option]);
		}
	};

	useEffect(() => {
		console.log('useEffect value', value);
		checkAll(value.length, index);
	}, [value]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button onClick={handleClick}>
				{value.length > 0 && value.length < options.length ? (
					<div
						style={{
							border: '2.5px solid #016193',
							borderRadius: '2px',
							width: '14px',
							height: '14px',
							position: 'relative',
							backgroundColor: '#016193',
						}}
					>
						<span
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: 'white',
								fontWeight: 'bold',
							}}
						>
							{value.length}
						</span>
					</div>
				) : value.length === options.length ? (
					<div
						style={{
							border: '2.5px solid #016193',
							borderRadius: '2px',
							width: '14px',
							height: '14px',
							position: 'relative',
							backgroundColor: '#016193',
						}}
					>
						<DoneAllIcon
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: 'white',
								fontSize: '18px',
							}}
						/>
					</div>
				) : (
					<div
						style={{
							border: '2.5px solid gray',
							borderRadius: '2px',
							width: '14px',
							height: '14px',
							position: 'relative',
							backgroundColor: 'white',
						}}
					/>
				)}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{options.map((option) => (
					<MenuItem key={option} onClick={handleClose}>
						<Checkbox checked={value.includes(option)} onChange={() => handleChange(option)} />
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

// export const CheckboxContainer = () => {
// 	const [options, setOptions] = useState<string[]>(['1', '2', '3']);
// 	const [value, setValue] = useState<string[]>([]);
// 	return <MultiCheckbox options={options} onChange={setValue} value={value} />;
// };
