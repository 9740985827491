import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {
	ALL_ORDER_STATUSES,
	NO_FILTERS,
	ORDERS_STATUS_COLLECTED,
	ORDERS_STATUS_COLLECTING,
	ORDERS_STATUS_FINISHED,
	ORDERS_STATUS_NOT_STARTED,
	ORDERS_STATUS_PROBLEMATIC,
} from 'redux/reducers/parcels/constants';
import { FC, useEffect, useState } from 'react';
import { OrderStratus } from 'components/Home/OrderStratus';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { TOrdersStatus } from 'redux/reducers/parcels/types';
import { TStatusFilterProps } from './types';

const options: (TOrdersStatus | typeof ALL_ORDER_STATUSES)[] = [
	ALL_ORDER_STATUSES,
	ORDERS_STATUS_NOT_STARTED,
	ORDERS_STATUS_COLLECTING,
	ORDERS_STATUS_COLLECTED,
	ORDERS_STATUS_FINISHED,
	ORDERS_STATUS_PROBLEMATIC,
];

export const StatusFilter: FC<TStatusFilterProps> = ({ onChange, status }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setSelectedIndex(index);
		setAnchorEl(null);
		onChange(index === 0 ? NO_FILTERS : (options[index] as TOrdersStatus));
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const index = options.findIndex((item) => item === status);
		setSelectedIndex(index);
	}, [status]);

	return (
		<div style={{ width: '165px', marginLeft: '10px' }}>
			<List
				component="nav"
				aria-label="Device settings"
				sx={{
					bgcolor: 'background.paper',
					borderRadius: '5px',
					height: '42px',
					m: 0,
					p: '4px',
				}}
			>
				<ListItem
					id="lock-button"
					aria-haspopup="listbox"
					aria-controls="lock-menu"
					aria-label="when device is locked"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClickListItem}
					sx={{ m: 0, p: 0 }}
					style={{ height: '40px' }}
				>
					<ListItemText
						primary={
							options[selectedIndex] === ALL_ORDER_STATUSES || status === NO_FILTERS ? (
								ALL_ORDER_STATUSES
							) : (
								<OrderStratus status={status} />
							)
						}
					/>{' '}
					{options[selectedIndex] === ALL_ORDER_STATUSES || status === NO_FILTERS ? (
						<FilterListOffIcon />
					) : (
						<FilterListIcon />
					)}
				</ListItem>
			</List>
			<Menu
				id="lock-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox',
				}}
				sx={{ left: '-4px', top: '7px' }}
			>
				{options.map((option, index) => (
					<MenuItem
						sx={{ minWidth: '165px' }}
						key={option}
						selected={index === selectedIndex}
						onClick={(event) => handleMenuItemClick(event, index)}
					>
						{option === 'All Order Statuses' ? (
							<div style={{ color: 'main.primary' }}>All Order Statuses</div>
						) : (
							<OrderStratus status={option} />
						)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
