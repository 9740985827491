import { Chip } from '@mui/material';
import { FC } from 'react';
import { TOrderStatusProps } from './types';
import {
	ORDERS_STATUS_COLLECTED,
	ORDERS_STATUS_COLLECTING,
	ORDERS_STATUS_PROBLEMATIC,
	ORDERS_STATUS_FINISHED,
	ORDERS_STATUS_NOT_STARTED,
} from '../../../redux/reducers/parcels/constants';

export const OrderStratus: FC<TOrderStatusProps> = ({ status, invert = false }) => {
	if (status === ORDERS_STATUS_FINISHED) {
		return <Chip label={status} color="success" size="small" />;
	}
	if (status === ORDERS_STATUS_COLLECTING) {
		return <Chip label={status} color="warning" size="small" />;
	}
	if (status === ORDERS_STATUS_COLLECTED) {
		return <Chip label={status} color="secondary" size="small" />;
	}
	if (status === ORDERS_STATUS_PROBLEMATIC) {
		return <Chip label={status} color="error" size="small" />;
	}

	if (invert) {
		return (
			<Chip
				label={status}
				color="primary"
				size="small"
				sx={{ background: 'white', color: '#016193' }}
			/>
		);
	}
	return <Chip label={status} color="primary" size="small" />;
};
