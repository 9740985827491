/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import fileDownload from 'js-file-download';
import {
	newVATReportRequest,
	newVATReportSuccess,
	settingsInitState,
	vatReportFileRequest,
	vatReportFileSuccess,
	vatReportStatusRequest,
	vatReportStatusSuccess,
} from './reducer';
import {
	TNewVATReportResponsePayload,
	TVatReportFileRequestPayload,
	TVatReportFileResponsePayload,
	TVatReportStatusRequestPayload,
	TVatReportStatusResponsePayload,
} from './types';

const date = new Date();

function* newVATReportWorker() {
	try {
		const response: TNewVATReportResponsePayload = yield call(api.vatreport.newVATReport);
		yield put(newVATReportSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(settingsInitState());
	}
}

function* vatReportStatusWorker(action: PayloadAction<TVatReportStatusRequestPayload>) {
	const { payload } = action;
	try {
		const response: TVatReportStatusResponsePayload = yield call(
			api.vatreport.vatReportStatus,
			payload,
		);
		yield put(vatReportStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		// yield put(settingsInitState());
	}
}

function* vatReportFileWorker(action: PayloadAction<TVatReportFileRequestPayload>) {
	const { payload } = action;
	try {
		const response: TVatReportFileResponsePayload = yield call(
			api.vatreport.vatReportFile,
			payload,
		);
		yield put(vatReportFileSuccess(response));
		if (response) {
			yield fileDownload(
				response,
				`VAT-report__${String(date.getDate())}-${String(date.getMonth() + 1)}-${String(
					date.getFullYear(),
				)}__${String(date.getHours())}-${String(date.getMinutes())}.xml`,
			);
		}
		yield put(settingsInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(settingsInitState());
	}
}

export function* vatReportSaga() {
	yield takeEvery(newVATReportRequest.type, newVATReportWorker);
	yield takeEvery(vatReportStatusRequest.type, vatReportStatusWorker);
	yield takeEvery(vatReportFileRequest.type, vatReportFileWorker);
}
