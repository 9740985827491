import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { TParcelsApi } from './types';

export const parcels: TParcelsApi = {
	getParcels: (params) =>
		http.get(endpoint.parcels.GET_PARCELS, { params }).then((response) => response.data),
	getCountries: () => http.get(endpoint.parcels.GET_COUNTRIES).then((response) => response.data),
	getParcel: (parcelId) =>
		http.get(endpoint.parcels.GET_PARCEL(parcelId)).then((response) => response.data),
	setCollected: (parcelId) => http.post(endpoint.parcels.SET_COLLECTED(parcelId)),
	setProblem: (parcelId) => http.post(endpoint.parcels.SET_PROBLEM(parcelId)),
	setLabel: (parcelId) =>
		http
			.get(endpoint.parcels.SET_LABEL(parcelId), { responseType: 'blob' })
			.then((response) => response.data),
	generatedLabel: (parcelId) =>
		http
			.get(endpoint.parcels.GENERATED_LABEL(parcelId), { responseType: 'blob' })
			.then((response) => response.data),
	getLastMileLabel: (params) =>
		http
			.get(endpoint.parcels.GET_LAST_MILE_LABEL, { params, responseType: 'blob' })
			.then((response) => response.data),
};
