import { auth } from './auth';
import { clients } from './clients';
import { files } from './files';
import { orders } from './orders';
import { parcels } from './parcels';
import { settings } from './settings';
import { TApi } from './types';
import { vatreport } from './vatreport';

const api: TApi = {
	auth,
	parcels,
	clients,
	files,
	orders,
	settings,
	vatreport,
};

export { api };
