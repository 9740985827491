import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TParcelData,
	TParcelLabelData,
	TParcelsCountriesData,
	TParcelsData,
	TParcelsStore,
} from './types';

// ==========================================:
const getParcelsState = (state: TStoreState): TParcelsStore => state.parcels;
export const getParcels = createSelector([getParcelsState], (parcels: TParcelsStore) => parcels);

// ====================================================:
export const getParcelsIsLoad = createSelector(
	[getParcels],
	(parcels: TParcelsStore): boolean => parcels.parcelsLoader,
);

// ====================================================:
export const getParcelsList = createSelector(
	[getParcels],
	(parcels: TParcelsStore): TParcelsData => parcels.parcels,
);

// ====================================================:

export const getCountriesIsLoad = createSelector(
	[getParcels],
	(parcels: TParcelsStore): boolean => parcels.countriesLoader,
);

// ====================================================:
export const getCountriesList = createSelector(
	[getParcels],
	(parcels: TParcelsStore): TParcelsCountriesData => parcels.countries,
);

// ====================================================:
export const getParcelIsLoad = createSelector(
	[getParcels],
	(parcels: TParcelsStore): boolean => parcels.parcelLoader,
);

// ====================================================:
export const getParcel = createSelector(
	[getParcels],
	(parcels: TParcelsStore): TParcelData => parcels.parcel,
);

// ====================================================:
export const getLabelIsLoad = createSelector(
	[getParcels],
	(parcels: TParcelsStore): boolean => parcels.labelLoader,
);

// ====================================================:
export const getLabel = createSelector(
	[getParcels],
	(parcels: TParcelsStore): TParcelLabelData => parcels.label,
);
