/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { getCountriesRequest, getParcelsRequest } from 'redux/reducers/parcels/reducer';
import { getParcelsIsLoad, getParcelsList } from 'redux/reducers/parcels/selectors';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import {
	TLabelStatus,
	TOrdersStatus,
	TParcelsItem,
	TParcelsRequestPayload,
} from 'redux/reducers/parcels/types';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { Loader } from 'ui/Loader';
import { SearchComponent } from 'ui/SearchComponent';
import { StatusFilter } from 'ui/StatusFilter';
import { CountryFilter } from 'ui/CountryFilter';
import { IconButton } from '@mui/material';
import { NO_FILTERS, ORDERS_STATUS_COLLECTED, PAGE_SIZE } from 'redux/reducers/parcels/constants';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { OrderStratus } from './OrderStratus';
import { LabelStatus } from './LabelStratus';

export const Home: FC = () => {
	const dispatch = useDispatch();
	const parcels = useSelector(getParcelsList);
	const parcelsIsLoad = useSelector(getParcelsIsLoad);

	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const { pageNumber, orderStatus, country, searchString } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);
	const [countryFilter, setCountryFilter] = useState((country as string) || NO_FILTERS);
	const [orderStatusFilter, setOrderStatusFilter] = useState<TOrdersStatus>(
		(orderStatus as TOrdersStatus) || NO_FILTERS,
	);
	const [searchStr, setSearchStr] = useState((searchString as string) || '');
	const [searchStrDebounce] = useDebounce(searchStr, 600);

	const setCurrentPageHandler = (page: number) => {
		setCurrentPage(page);
		const params: TParcelsRequestPayload = {
			pageNumber: page || 1,
			pageSize: PAGE_SIZE,
			country: countryFilter === NO_FILTERS ? undefined : countryFilter,
			status: orderStatusFilter === NO_FILTERS ? undefined : orderStatusFilter,
			searchString: searchStr || undefined,
		};
		dispatch(getParcelsRequest(params));
	};

	const handlePageChange = (event: unknown, page: number) => {
		if (page === currentPage) return;
		setCurrentPageHandler(page);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=${String(page)}${
				orderStatusFilter !== NO_FILTERS ? `&orderStatus=${orderStatusFilter}` : ''
			}${countryFilter !== NO_FILTERS ? `&country=${countryFilter}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}`,
		});
	};

	const handleCountryFilter = (countryF: string) => {
		setCountryFilter(countryF);
		setCurrentPage(1);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				orderStatusFilter !== NO_FILTERS ? `&orderStatus=${orderStatusFilter}` : ''
			}${countryF !== NO_FILTERS ? `&country=${countryF}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}`,
		});
	};

	const handleOrderStatusFilter = (status: TOrdersStatus) => {
		setOrderStatusFilter(status);
		setCurrentPage(1);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${status !== NO_FILTERS ? `&orderStatus=${status}` : ''}${
				countryFilter !== NO_FILTERS ? `&country=${countryFilter}` : ''
			}${searchStr !== '' ? `&searchString=${searchStr}` : ''}`,
		});
	};

	const handleSearchStr = (value: string) => {
		setCurrentPage(1);
		setSearchStr(value);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				orderStatusFilter !== NO_FILTERS ? `&orderStatus=${orderStatusFilter}` : ''
			}${countryFilter !== NO_FILTERS ? `&country=${countryFilter}` : ''}${
				value !== '' ? `&searchString=${value}` : ''
			}`,
		});
	};

	const handleClearAll = () => {
		// navigate({
		// 	pathname: location.pathname,
		// 	search: `?pageNumber=1`,
		// });
		setCurrentPage(1);
		setCountryFilter(NO_FILTERS);
		setOrderStatusFilter(NO_FILTERS);
		setSearchStr('');
		navigate({
			pathname: location.pathname,
			search: ``,
		});
	};

	useEffect(() => {
		dispatch(getCountriesRequest());
	}, []);

	useEffect(() => {
		// navigate({
		// 	pathname: location.pathname,
		// 	search: `?pageNumber=1`,
		// });
		setCurrentPage(1);

		const params: TParcelsRequestPayload = {
			pageNumber: currentPage || 1,
			pageSize: PAGE_SIZE,
			status: orderStatusFilter === NO_FILTERS ? undefined : orderStatusFilter,
			country: countryFilter === NO_FILTERS ? undefined : countryFilter,
			searchString: searchStrDebounce || undefined,
		};
		dispatch(getParcelsRequest(params));
	}, [dispatch, orderStatusFilter, countryFilter, searchStrDebounce]);

	useEffect(() => {
		// setCurrentPageHandler(+(pageNumber ?? 1));
		setCurrentPage(+(pageNumber ?? 1));
		if (orderStatus) setOrderStatusFilter(orderStatus as TOrdersStatus);
	}, [dispatch, pageNumber, orderStatus]);

	// useEffect(() => {
	// 	setOrderStatusFilter(orderStatus as TOrdersStatus);
	// }, [orderStatus]);

	const handlerParcelClick = (item: TParcelsItem) => {
		// navigate(
		// 	`/parcel/${item.id}${location.search}${location.search ? '&' : '?'}selfStatus=${
		// 		item.orderStatus
		// 	}`,
		// );

		navigate({
			pathname: `/parcel/${item.id}`,
			search: `?pageNumber=${String(currentPage)}${
				orderStatusFilter !== NO_FILTERS ? `&orderStatus=${orderStatusFilter}` : ''
			}${countryFilter !== NO_FILTERS ? `&country=${countryFilter}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${item.orderStatus !== NO_FILTERS ? `&selfStatus=${item.orderStatus}` : ''}`,
		});
	};

	return (
		<Container maxWidth="xl">
			<>
				<TableContainer component={Paper}>
					<div
						style={{
							background: '#016193',
							padding: '10px 10px 0 10px',
							display: 'flex',
							justifyContent: 'end',
							height: '50px',
							paddingBottom: '10px',
						}}
					>
						{(countryFilter !== NO_FILTERS || orderStatusFilter !== NO_FILTERS || searchStr) && (
							<IconButton onClick={handleClearAll}>
								<FilterListOffIcon style={{ color: '#fff' }} fontSize="large" />
							</IconButton>
						)}
						<SearchComponent onChange={handleSearchStr} search={searchStr} />
						<CountryFilter onChange={handleCountryFilter} country={countryFilter} />
						<StatusFilter onChange={handleOrderStatusFilter} status={orderStatusFilter} />
					</div>
					{!parcels?.data.length && !parcelsIsLoad && (
						<div className="empty-data">
							<p className="empty__text">No parcels found...</p>
						</div>
					)}
					{!!parcelsIsLoad && <Loader />}
					{!!parcels?.data.length && !parcelsIsLoad && (
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center" colSpan={2}>
										id
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={3}>
										Delivery Name
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={3}>
										Address Line 1
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Address City
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Address State
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Postal Code
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Full Country
									</StyledTableCell>
									{/* <StyledTableCell align="center" colSpan={2}>
										Total product cost
									</StyledTableCell> */}
									<StyledTableCell align="center" colSpan={2}>
										Shipping weight kg (package included)
									</StyledTableCell>
									{/* <StyledTableCell align="center" colSpan={2}>
										Shipping EUR
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Order total
									</StyledTableCell> */}

									{/* <StyledTableCell align="center" colSpan={2}>
										Label status
									</StyledTableCell> */}
									<StyledTableCell align="center" colSpan={2}>
										Order status
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{parcels?.data.map((item) => (
									<StyledTableRow hover key={item.id} onClick={() => handlerParcelClick(item)}>
										<StyledTableCell align="center" colSpan={2}>
											{item.id}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={3}>
											{item.deliveryName}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.addressLine1}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={3}>
											{item.addressCity}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.addressState}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.postalCode}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{/* <Chip
												label={item.Country_name}
												style={{ backgroundColor: '#03a9f4', color: 'white' }}
												size="small"
											/> */}
											{item.countryName}
										</StyledTableCell>
										{/* <StyledTableCell align="center" colSpan={2}>
											{item.order_product_total}
										</StyledTableCell> */}
										<StyledTableCell align="center" colSpan={2}>
											{item.weight}
										</StyledTableCell>
										{/* <StyledTableCell align="center" colSpan={2}>
											{item.shipping_price}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.Order_total}
										</StyledTableCell> */}
										{/* <StyledTableCell align="center" colSpan={2}>
											<LabelStatus status={item.labelStatus as TLabelStatus} />
										</StyledTableCell> */}
										<StyledTableCell align="center" colSpan={2}>
											<OrderStratus status={item.orderStatus as TOrdersStatus} />
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					)}
				</TableContainer>
				{(parcels?.totalCount ?? 0) > PAGE_SIZE && (
					<Stack spacing={2} marginTop={2} style={{ marginBottom: '20px' }}>
						<Pagination
							count={Math.ceil((parcels?.totalCount ?? 0) / PAGE_SIZE)}
							page={currentPage}
							onChange={handlePageChange}
							// variant="outlined"
							shape="rounded"
							color="primary"
						/>
					</Stack>
				)}
			</>
		</Container>
	);
};
