import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TVATReportApi } from './types';

export const vatreport: TVATReportApi = {
	newVATReport: () =>
		http.post(endpoint.vatReport.NEW_VAT_REPORT).then((response) => response.data),
	vatReportStatus: (payload) =>
		http
			.get(endpoint.vatReport.VAT_REPORT_STATUS(payload.taskId))
			.then((response) => response.data),
	vatReportFile: (payload) =>
		http.get(endpoint.vatReport.VAT_REPORT_FILE(payload.taskId)).then((response) => response.data),
};
