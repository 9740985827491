import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TGeneratedLabelRequestPayload,
	TGeneratedLabelResponsePayload,
	TGetLastMileLabel,
	TGetLastMileLabelRequestPayload,
	TGetLastMileLabelResponsePayload,
	TParcelLabelResponsePayload,
	TParcelRequestPayload,
	TParcelResponsePayload,
	TParcelsCountriesResponsePayload,
	TParcelsRequestPayload,
	TParcelsResponsePayload,
	TParcelsStore,
	TSetParcelCollectedPayload,
	TSetParcelLabelRequestPayload,
	TSetParcelProblemRequestPayload,
} from './types';
// ==========================================:
export const initialState: TParcelsStore = {
	parcels: null,
	parcelsLoader: false,
	countries: null,
	countriesLoader: false,
	parcel: null,
	parcelLoader: false,
	label: null,
	labelLoader: false,
	generatedLabel: null,
	generatedLabelLoader: false,
	lastMileLabel: null,
	lastMileLabelLoader: false,
};

// ==========================================:
const parcels = createSlice({
	name: '@@parcels',
	initialState,
	reducers: {
		getParcelsRequest: (state: TParcelsStore, action: PayloadAction<TParcelsRequestPayload>) => {
			const parcelsRequestState = state;
			parcelsRequestState.parcelsLoader = true;
		},
		getParcelsSuccess: (state: TParcelsStore, action: PayloadAction<TParcelsResponsePayload>) => {
			const { payload } = action;
			const parcelsSuccessState = state;
			parcelsSuccessState.parcels = payload;
			parcelsSuccessState.parcelsLoader = false;
		},
		getCountriesRequest: (state: TParcelsStore) => {
			const countriesRequestState = state;
			countriesRequestState.countriesLoader = true;
		},
		getCountriesSuccess: (
			state: TParcelsStore,
			action: PayloadAction<TParcelsCountriesResponsePayload>,
		) => {
			const { payload } = action;
			const countriesSuccessState = state;
			countriesSuccessState.countries = payload;
			countriesSuccessState.countriesLoader = false;
		},
		getParcelRequest: (state: TParcelsStore, action: PayloadAction<TParcelRequestPayload>) => {
			const parcelRequestState = state;
			parcelRequestState.parcelLoader = true;
		},
		getParcelSuccess: (state: TParcelsStore, action: PayloadAction<TParcelResponsePayload>) => {
			const { payload } = action;
			const parcelSuccessState = state;
			parcelSuccessState.parcel = payload;
			parcelSuccessState.parcelLoader = false;
		},
		setParcelCollectedRequest: (
			state: TParcelsStore,
			action: PayloadAction<TSetParcelCollectedPayload>,
		) => {
			const { payload } = action;
			const setCollectedState = state;
		},
		setParcelProblemRequest: (
			state: TParcelsStore,
			action: PayloadAction<TSetParcelProblemRequestPayload>,
		) => {
			const { payload } = action;
			const setProblemState = state;
		},
		getLabelRequest: (
			state: TParcelsStore,
			action: PayloadAction<TSetParcelLabelRequestPayload>,
		) => {
			const { payload } = action;
			const getLabelState = state;
			getLabelState.labelLoader = true;
		},
		getLabelSuccess: (state: TParcelsStore, action: PayloadAction<TParcelLabelResponsePayload>) => {
			const { payload } = action;
			const getLabelState = state;
			getLabelState.labelLoader = false;
			getLabelState.label = payload;
		},
		setLabelNull: (state: TParcelsStore) => {
			const getLabelState = state;
			getLabelState.labelLoader = false;
			getLabelState.label = null;
		},
		generatedLabelRequest: (
			state: TParcelsStore,
			action: PayloadAction<TGeneratedLabelRequestPayload>,
		) => {
			const { payload } = action;
			const generatedLabelState = state;
			generatedLabelState.generatedLabelLoader = true;
		},
		generatedLabelSuccess: (
			state: TParcelsStore,
			action: PayloadAction<TGeneratedLabelResponsePayload>,
		) => {
			const { payload } = action;
			const generatedLabelState = state;
			generatedLabelState.generatedLabelLoader = false;
			generatedLabelState.generatedLabel = payload;
		},
		getLastMileLabelRequest: (
			state: TParcelsStore,
			action: PayloadAction<TGetLastMileLabelRequestPayload>,
		) => {
			const { payload } = action;
			const getLastMileLabelState = state;
			getLastMileLabelState.lastMileLabelLoader = true;
		},
		getLastMileLabelSuccess: (
			state: TParcelsStore,
			action: PayloadAction<TGetLastMileLabelResponsePayload>,
		) => {
			const { payload } = action;
			const getLastMileLabelState = state;
			getLastMileLabelState.lastMileLabelLoader = false;
			getLastMileLabelState.lastMileLabel = payload;
		},

		parcelsInitState: () => initialState,
	},
});

export default parcels.reducer;
export const {
	getParcelsRequest,
	getParcelsSuccess,
	getCountriesRequest,
	getCountriesSuccess,
	getParcelRequest,
	getParcelSuccess,
	setParcelCollectedRequest,
	setParcelProblemRequest,
	getLabelRequest,
	getLabelSuccess,
	setLabelNull,
	generatedLabelRequest,
	generatedLabelSuccess,
	getLastMileLabelRequest,
	getLastMileLabelSuccess,
	parcelsInitState,
} = parcels.actions;
