import { Parcel } from 'components/Parcel/index';
import { Page } from 'layouts/Page';
import { LoginPage } from 'pages/LoginPage';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';

export const ParcelPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="Parcel">
			<Parcel />
		</Page>
	) : (
		<LoginPage />
	);
};
