import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import orders from './reducers/orders/reducer';
import clients from './reducers/clients/reducer';
import files from './reducers/files/reducer';
import settings from './reducers/settings/reducer';
import parcels from './reducers/parcels/reducer';
import auth from './reducers/auth/reducer';
import vatreport from './reducers/vatreport/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		parcels,
		clients,
		files,
		orders,
		settings,
		auth,
		vatreport,
	});

export default createRootReducer;
