/* eslint-disable import/extensions */
import { ParcelPage } from 'pages/ParcelPage';
import { HomePage } from 'pages/HomePage';
import { LoginPage } from 'pages/LoginPage';
import { useRoutes } from 'react-router';
import { LastMailPage } from 'pages/LastMailPage';

export const NavigateList = () => {
	const routes = useRoutes([
		{
			path: `/login`,
			element: <LoginPage />,
		},
		{
			path: `/`,
			element: <HomePage />,
		},
		{
			path: `/parcel/:parcelId`,
			element: <ParcelPage />,
		},
		{
			path: `/print-last-mail-label`,
			element: <LastMailPage />,
		},
	]);
	return routes;
};
