/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	parcelsInitState,
	getParcelsRequest,
	getParcelsSuccess,
	getCountriesSuccess,
	getCountriesRequest,
	getParcelRequest,
	getParcelSuccess,
	setParcelCollectedRequest,
	setParcelProblemRequest,
	getLabelRequest,
	getLabelSuccess,
	generatedLabelSuccess,
	generatedLabelRequest,
	getLastMileLabelRequest,
} from './reducer';
import {
	TGeneratedLabelRequestPayload,
	TGetLastMileLabelRequestPayload,
	TParcelLabelResponsePayload,
	TParcelRequestPayload,
	TParcelResponsePayload,
	TParcelsCountriesResponsePayload,
	TParcelsRequestPayload,
	TParcelsResponsePayload,
	TSetParcelCollectedPayload,
	TSetParcelProblemRequestPayload,
} from './types';

function* getParcelsWorker(action: PayloadAction<TParcelsRequestPayload>) {
	const { payload } = action;
	try {
		const response: TParcelsResponsePayload = yield call(api.parcels.getParcels, payload);
		yield put(getParcelsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(parcelsInitState());
	}
}

function* getParcelsCountriesWorker() {
	try {
		const response: TParcelsCountriesResponsePayload = yield call(api.parcels.getCountries);
		yield put(getCountriesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(parcelsInitState());
	}
}

function* getParcelWorker(action: PayloadAction<TParcelRequestPayload>) {
	const { payload } = action;
	try {
		const response: TParcelResponsePayload = yield call(api.parcels.getParcel, payload.parcelId);
		yield put(getParcelSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(parcelsInitState());
	}
}

function* setCollectedWorker(action: PayloadAction<TSetParcelCollectedPayload>) {
	const { payload } = action;
	try {
		yield call(api.parcels.setCollected, payload.parcelId);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* setProblemWorker(action: PayloadAction<TSetParcelProblemRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.parcels.setProblem, payload.parcelId);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* getLabelWorker(action: PayloadAction<TParcelRequestPayload>) {
	const { payload } = action;
	try {
		const response: TParcelLabelResponsePayload = yield call(
			api.parcels.setLabel,
			payload.parcelId,
		);
		yield put(getLabelSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* getGeneratedLabelWorker(action: PayloadAction<TGeneratedLabelRequestPayload>) {
	const { payload } = action;

	try {
		const response: TParcelLabelResponsePayload = yield call(
			api.parcels.generatedLabel,
			payload.parcelId,
		);
		yield put(getLabelSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* getLastMileLabelWorker(action: PayloadAction<TGetLastMileLabelRequestPayload>) {
	const { payload } = action;

	try {
		const response: TParcelLabelResponsePayload = yield call(api.parcels.getLastMileLabel, payload);
		yield put(getLabelSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

export function* parcelsSaga() {
	yield takeEvery(getParcelsRequest.type, getParcelsWorker);
	yield takeEvery(getCountriesRequest.type, getParcelsCountriesWorker);
	yield takeEvery(getParcelRequest.type, getParcelWorker);
	yield takeEvery(setParcelCollectedRequest.type, setCollectedWorker);
	yield takeEvery(setParcelProblemRequest.type, setProblemWorker);
	yield takeEvery(getLabelRequest.type, getLabelWorker);
	yield takeEvery(generatedLabelRequest.type, getGeneratedLabelWorker);
	yield takeEvery(getLastMileLabelRequest.type, getLastMileLabelWorker);
}
