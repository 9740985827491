import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FC, useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useSelector } from 'react-redux';
import { getCountriesList } from 'redux/reducers/parcels/selectors';
import { ALL_COUNTRIES, NO_FILTERS } from 'redux/reducers/parcels/constants';
import { TCountryFilterProps } from './types';

const options1 = [
	ALL_COUNTRIES,
	'Austria',
	'Australia',
	'Belgium',
	'Bulgaria',
	'Canada',
	'China',
	'Croatia',
	'Czech Republic',
	'Denmark',
	'Estonia',
	'Finland',
	'France',
	'Germany',
	'Greece',
	'Hungary',
	'Ireland',
	'Italy',
	'Japan',
	'Latvia',
];

export const CountryFilter: FC<TCountryFilterProps> = ({ country, onChange }) => {
	const countries = useSelector(getCountriesList);
	const [options, setOptions] = useState(options1);
	useEffect(() => {
		if (countries) {
			setOptions([ALL_COUNTRIES, ...(countries.countries.map((item) => item.name) ?? [])]);
		}
	}, [countries]);

	// const options = [
	// 	ALL_COUNTRIES,
	// 	...(countries?.map((country) => {
	// 		return country.name;
	// 	}) ?? []),
	// ];

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setSelectedIndex(index);
		setAnchorEl(null);
		onChange(index === 0 ? NO_FILTERS : options[index]);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const index = options.findIndex((item) => item === country);
		setSelectedIndex(index);
	}, [country, options]);

	return (
		<div style={{ width: '150px', marginLeft: '10px' }}>
			<List
				component="nav"
				aria-label="Device settings"
				sx={{
					bgcolor: 'background.paper',
					borderRadius: '5px',
					height: '42px',
					m: 0,
					p: '4px',
					w: '100%',
				}}
			>
				<ListItem
					id="lock-button"
					aria-haspopup="listbox"
					aria-controls="lock-menu"
					aria-label="when device is locked"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClickListItem}
					sx={{ m: 0, p: 0 }}
					style={{ height: '40px' }}
				>
					<ListItemText
						primary={
							options[selectedIndex] === ALL_COUNTRIES || country === NO_FILTERS
								? ALL_COUNTRIES
								: country
						}
					/>{' '}
					{options[selectedIndex] === ALL_COUNTRIES || country === NO_FILTERS ? (
						<FilterListOffIcon />
					) : (
						<FilterListIcon />
					)}
				</ListItem>
			</List>
			<Menu
				id="lock-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox',
				}}
				sx={{ left: '-4px', top: '7px', minWidth: '130px' }}
			>
				{options.map((option, index) => (
					<MenuItem
						sx={{ minWidth: '150px' }}
						key={option}
						selected={index === selectedIndex}
						onClick={(event) => handleMenuItemClick(event, index)}
					>
						{option === ALL_COUNTRIES ? (
							<div style={{ color: 'main.primary' }}>All Countries</div>
						) : (
							option
						)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
