export const ORDERS_STATUS_COLLECTING = 'Collecting';
export const ORDERS_STATUS_COLLECTED = 'Collected';
export const ORDERS_STATUS_NOT_STARTED = 'Not started';
export const ORDERS_STATUS_FINISHED = 'Finished';
export const ORDERS_STATUS_PROBLEMATIC = 'Problematic';

export const LABEL_STATUS_NO_LABEL = 'No Label';
export const LABEL_STATUS_GENERATED = 'Generated';
export const LABEL_STATUS_DOWNLOADED = 'Downloaded';

export const NO_FILTERS = 'No filters';

export const ALL_ORDER_STATUSES = 'All Order Statuses';
export const ALL_COUNTRIES = 'All Countries';

export const PAGE_SIZE = 10;
